import { useExternalShipmentQuery } from '@/generated/graphql';
import { formatAddress } from '@/utilities/address';
import PackStatusPill from '@components/shipment/PackStatusPill';
import Skeleton from '@components/skeleton/Skeleton';
import Link from 'next/link';
import { Routes, linkTo } from 'utilities/routes';

type Props = {
  shipmentId: string;
};
export const ExternalShipmentDetails = ({ shipmentId }: Props) => {
  const { data, loading, error } = useExternalShipmentQuery({
    variables: { id: shipmentId },
  });
  const shipment = data?.externalShipment;

  return (
    <div className="px-3">
      <Link
        className="border border-secondary rounded p-4 block"
        href={linkTo(Routes.externalShipment, { id: shipmentId })}
      >
        {loading ? <Skeleton height={75} /> : null}
        {error?.message ? (
          <p className="text-warning">{error.message}</p>
        ) : null}
        {shipment ? (
          <div className="flex flex-col gap-1">
            <p className="flex gap-2 items-baseline">
              <span className="text-lg">{shipment?.trackingNumber}</span>
              <PackStatusPill status={shipment.status} />
            </p>
            {shipment.address ? (
              <p className="text-sm">{formatAddress(shipment.address)}</p>
            ) : null}
            <p className="text-secondary text-sm">
              sent by{' '}
              <Link
                href={linkTo(Routes.merchant, { id: shipment.organization.id })}
                className="text-link"
              >
                {shipment.organization.name}
              </Link>
            </p>
          </div>
        ) : null}
      </Link>
    </div>
  );
};
