import {
  formatClaimStatus,
  getClassNamesForClaimStatus,
} from '@/components/claims/utilities';
import Skeleton from '@/components/skeleton/Skeleton';
import {
  ClaimReviewRequiredFragment,
  useClaimQuery,
} from '@/generated/graphql';
import { formatMoney } from '@/utilities/money';
import { TZ } from '@/utilities/timezone';
import { formatIso8601ToFriendlyDateShort } from '@packfleet/datetime';
import Link from 'next/link';
import { twMerge } from 'tailwind-merge';
import { Routes, linkTo } from 'utilities/routes';

type Props = {
  task: ClaimReviewRequiredFragment;
};

export const ClaimDetails = ({ task }: Props) => {
  const {
    data,
    error: unhandledError,
    loading,
  } = useClaimQuery({ variables: { id: task.claimId } });

  const error = unhandledError?.message || data?.claim?.error?.message;
  const claim = data?.claim?.claim;

  const href = task.claimShipmentId
    ? `${linkTo(Routes.shipment, { id: task.claimShipmentId })}#claims`
    : task.claimExternalShipmentId
      ? `${linkTo(Routes.externalShipment, { id: task.claimExternalShipmentId })}#claims`
      : '';

  const Component = href ? Link : 'div';

  return (
    <div className="px-3">
      <Component
        className="border border-secondary rounded p-4 block"
        href={href}
      >
        {loading ? <Skeleton height={75} /> : null}
        {error ? <p className="text-warning">{error}</p> : null}
        {claim ? (
          <div className="flex flex-col gap-1">
            <p className="flex gap-2 items-baseline justify-between">
              <span className="text-lg">{claim.type}</span>
              <div
                className={twMerge(
                  'rounded px-2 text-sm',
                  getClassNamesForClaimStatus(claim.status),
                )}
              >
                {formatClaimStatus(claim.status)}
              </div>
            </p>
            {claim.sourceUser ? (
              <p className="text-sm">{claim.sourceUser?.name}</p>
            ) : null}
            <div className="flex justify-between gap-4 flex-wrap">
              <p className="text-secondary text-sm">
                {claim.amount && claim.currency
                  ? formatMoney(claim.amount, claim.currency)
                  : null}
              </p>
              <p className="text-secondary text-sm">
                Updated {formatIso8601ToFriendlyDateShort(claim.updatedAt, TZ)}
              </p>
            </div>
          </div>
        ) : null}
      </Component>
    </div>
  );
};
