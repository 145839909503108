import { ClaimReviewRequiredFragment } from 'generated/graphql';
import { ClaimDetails } from './utils/ClaimDetails';
import { ExternalShipmentDetails } from './utils/ExternalShipmentDetails';
import { SectionHeading } from './utils/SectionHeading';
import { ShipmentDetails } from './utils/ShipmentDetails';

type Props = {
  task: ClaimReviewRequiredFragment;
  taskId: string;
};

export const ClaimReviewRequired = ({ task }: Props) => {
  return (
    <div className="flex flex-col gap-4 py-3">
      {task.claimShipmentId ? (
        <ShipmentDetails shipmentId={task.claimShipmentId} />
      ) : null}
      {task.claimExternalShipmentId ? (
        <ExternalShipmentDetails shipmentId={task.claimExternalShipmentId} />
      ) : null}
      <SectionHeading title="Details" />
      <ClaimDetails task={task} />
    </div>
  );
};
